// Favicon
import './images/favicon.ico';

// Development index.html file
import './development-resources/index.html';

// import mutations / getters

// Vue
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

import 'animate.css/animate.min.css';
import ElementUI from 'element-ui';
import ElementUILocaleEnglish from 'element-ui/lib/locale/lang/en';
import 'leaflet/dist/leaflet.css';
import './scss/element-ui-variables.scss';

import AgencyIndex from './components/AgencyIndex.vue';
import AgencyPredictionsContainer from './components/AgencyPredictionsContainer.vue';
import App from './components/App.vue';
import * as getters from './store/getters';
import * as mutations from './store/mutations';
import state from './store/state';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(ElementUI, { locale: ElementUILocaleEnglish });

const vueInstance = new Vue({
  el: document.getElementById('main'),
  store: new Vuex.Store({
    strict: false, // ( process.env.NODE_ENV !== 'production' )
    state,
    getters,
    mutations,
  }),
  router: new VueRouter({
    mode: 'history',
    routes: [
      {
        name: 'default',
        path: '/',
        component: AgencyIndex,
      },
      {
        name: 'agency',
        path: '/:agencyKey',
        component: AgencyPredictionsContainer,
        beforeEnter: (to, from, next) => {
          const agency = to.params.agencyKey;

          if (window.agencies.some(({ agencyId }) => agencyId === agency)) {
            next();
            return;
          }

          // Not permitted, send to index
          // TODO: logging if user tries to access non-permitted agency?
          next({ name: 'default' });
        },
        children: [
          {
            name: 'agency/route',
            path: 'route/:routeKey',
          },
          {
            name: 'agency/route/stop',
            path: 'route/:routeKey/stop/:stopKey',
          },
        ],
      },
    ],
  }),
  render: function (h) {
    return h(App);
  },
});

if (process.env.NODE_ENV === 'development') {
  window.vueInstance = vueInstance;
}
