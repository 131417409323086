import _ from 'lodash';

// <direct state getters>
export const agencyKey = (state) => state.agencyKey;
export const agencyName = (state) => state.agencyName;
export const agencyBounds = (state) => state.agencyBounds;
export const allRouteDetails = (state) => state.allRouteDetails;
export const currentDirectionId = (state) => state.currentDirectionId;
export const currentPredictions = (state) => state.currentPredictions;
export const currentRouteShortName = (state) => state.currentRouteShortName;
export const currentStopCode = (state) => state.currentStopCode;
export const currentVehicleId = (state) => state.currentVehicleId;
export const heartbeat = (state) => state.heartbeat;
export const initialRouteKey = (state) => state.initialRouteKey;
export const initialStopKey = (state) => state.initialStopKey;
export const isLoadingPredictions = (state) => state.isLoadingPredictions;
export const isTrackingEnabled = (state) => state.isTrackingEnabled;
export const lastUpdatedText = (state) => state.lastUpdatedText;
export const selectedStopCode = (state) => state.selectedStopCode;
export const vehicles = (state) => JSON.parse(state.vehicles);
export const hideLogoSection = (state) => state.hideLogoSection;
// </direct state getters>

export const allCurrentRouteStops = (state, getters) => {
  const directionOptions = getters.currentDirectionOptions;
  let allRouteStops = [];
  _.forEach(directionOptions, (direction) => {
    allRouteStops = allRouteStops.concat(direction.stops);
  });
  return allRouteStops;
};

export const currentBounds = (state, getters) => {
  const DEFAULT_RETURN_VALUE = getters.agencyBounds;
  const routeBounds = getters.currentRouteBounds;
  if (_.isEmpty(routeBounds)) {
    return DEFAULT_RETURN_VALUE;
  }
  return routeBounds;
};

export const currentDirectionDetails = (state, getters) => {
  const DEFAULT_RETURN_VALUE = null;
  const directionId = getters.currentDirectionId;
  const directionOptions = getters.currentDirectionOptions;
  const directionDetails = _.find(
    directionOptions,
    (direction) => direction.id === directionId,
  );
  if (!directionDetails) {
    return DEFAULT_RETURN_VALUE;
  }
  return directionDetails;
};

export const currentDirectionDisplayName = (state, getters) => {
  const DEFAULT_RETURN_VALUE = '';
  const directionDetails = getters.currentDirectionDetails;
  if (_.isEmpty(directionDetails)) {
    return DEFAULT_RETURN_VALUE;
  }
  if (typeof directionDetails.value !== 'string') {
    return DEFAULT_RETURN_VALUE;
  }
  return directionDetails.value;
};

export const currentDirectionOptions = (state, getters) => {
  const DEFAULT_RETURN_VALUE = [];
  const routeDetails = getters.currentRouteDetails;
  const directionDetails = _.get(routeDetails, 'directions');
  if (_.isEmpty(directionDetails)) {
    return DEFAULT_RETURN_VALUE;
  }
  const currentDirectionOptions = [];
  _.forEach(directionDetails, (direction) => {
    currentDirectionOptions.push({
      value: direction.title,
      label: direction.title,
      stops: direction.stops,
      id: direction.id,
    });
  });
  return currentDirectionOptions;
};

export const currentRouteBounds = (state, getters) => {
  const DEFAULT_RETURN_VALUE = null;
  const allRouteStops = getters.allCurrentRouteStops;
  if (_.isEmpty(allRouteStops)) {
    return DEFAULT_RETURN_VALUE;
  }
  let minLat = 90;
  let minLon = 180;
  let maxLat = -90;
  let maxLon = -180;
  _.forEach(allRouteStops, (stop) => {
    const { lat, lon } = stop;
    if (lat < minLat) {
      minLat = lat;
    }
    if (lat > maxLat) {
      maxLat = lat;
    }
    if (lon < minLon) {
      minLon = lon;
    }
    if (lon > maxLon) {
      maxLon = lon;
    }
  });
  return [
    [minLat, minLon],
    [maxLat, maxLon],
  ];
};

export const currentRouteDetails = (state, getters) => {
  const DEFAULT_RETURN_VALUE = null;
  const allRouteDetails = getters.allRouteDetails;
  const routeShortName = getters.currentRouteShortName;
  if (allRouteDetails == null || routeShortName == null) {
    return DEFAULT_RETURN_VALUE;
  }
  const currentRouteDetails = allRouteDetails.find(
    (routeDetails) => routeDetails.shortName === routeShortName,
  );
  if (!currentRouteDetails) {
    return DEFAULT_RETURN_VALUE;
  }
  return currentRouteDetails;
};

export const currentRouteOptions = (state, getters) => {
  const DEFAULT_RETURN_VALUE = [];
  const allRoutesDetails = getters.allRouteDetails;
  if (_.isEmpty(allRoutesDetails)) {
    return DEFAULT_RETURN_VALUE;
  }
  const routeOptions = [];
  _.forEach(allRoutesDetails, (route) => {
    if (!_.isObjectLike(route)) {
      return;
    }
    if (typeof route.shortName !== 'string') {
      return;
    }
    if (typeof route.name !== 'string') {
      return;
    }
    routeOptions.push({
      value: route.shortName,
      label: route.name,
    });
  });
  return routeOptions;
};

export const currentRouteShapes = (state, getters) => {
  const DEFAULT_RETURN_VALUE = [];
  const routeDetails = getters.currentRouteDetails;
  return _.get(routeDetails, 'shapes', DEFAULT_RETURN_VALUE);
};

export const currentStop = (state, getters) => {
  const DEFAULT_RETURN_VALUE = null;
  const stopCode = getters.currentStopCode;
  const stopOptions = getters.currentStopOptions;
  if (_.isEmpty(stopOptions)) {
    return DEFAULT_RETURN_VALUE;
  }
  const stop = _.find(stopOptions, (option) => option.code === stopCode);
  if (!_.isObjectLike(stop)) {
    return DEFAULT_RETURN_VALUE;
  }
  return stop;
};

export const currentStopOptions = (state, getters) => {
  const DEFAULT_RETURN_VALUE = [];
  const currentDirectionStops = _.get(getters.currentDirectionDetails, 'stops');
  if (_.isEmpty(currentDirectionStops)) {
    return DEFAULT_RETURN_VALUE;
  }
  const firstStopCode = _.get(_.first(currentDirectionStops), 'code');
  const uniqueFieldName =
    _.isFinite(firstStopCode) || _.isString(firstStopCode) ? 'code' : 'id';
  const uniqueStops = _.uniqBy(currentDirectionStops, uniqueFieldName);
  const currentStopOptions = [];
  _.forEach(uniqueStops, (stop) => {
    const value = stop.code != null ? stop.code : stop[uniqueFieldName];
    currentStopOptions.push({
      value,
      label: stop.name,
      name: stop.name,
      code: stop[uniqueFieldName],
      lat: stop.lat,
      lon: stop.lon,
    });
  });
  return currentStopOptions;
};

export const arrivalTimesArray = (state, getters) => {
  const LESS_THAN_1_MINUTE_TEXT = '< 1';
  const SINGULAR_TIME_LABEL = 'minute';
  const PLURAL_TIME_LABEL = 'minutes';

  const destinationsWithPredictions = getters.currentPredictions;
  if (!_.isArray(destinationsWithPredictions)) {
    return [];
  }
  if (_.isEmpty(destinationsWithPredictions)) {
    return [];
  }

  const predictionsToReturn = _.map(destinationsWithPredictions, (dest) => {
    // TODO: Investigate why we are using _.map. Isn't there just one item in array? Should refactor?
    let timesString = '';
    let hasScheduleBased = false;
    let hasPrediction = true;
    if (_.isArray(dest.predictions) && dest.predictions.length) {
      _.forEach(dest.predictions, (pred, index) => {
        if (pred.scheduleBased) {
          hasScheduleBased = true;
        }
        let minutesAway = _getTimeDiffInMinutes(pred.time);
        let minutesLabel = PLURAL_TIME_LABEL;
        const scheduleLabel = pred.scheduleBased ? '*' : '';

        if (minutesAway <= 1) {
          minutesLabel = SINGULAR_TIME_LABEL;
        }
        if (minutesAway < 1) {
          minutesAway = LESS_THAN_1_MINUTE_TEXT;
        }

        const isLastPrediction = index === dest.predictions.length - 1;
        if (!isLastPrediction) {
          timesString += `${minutesAway}${scheduleLabel}, `;
        } else {
          timesString += `${minutesAway}${scheduleLabel} ${minutesLabel}.`;
        }
      });

      if (timesString) {
        timesString = `Arriving in ${timesString}`;
      }
    } else {
      hasPrediction = false;
    }
    return {
      headsign: dest.headsign,
      timesString: timesString || 'Arrival times unavailable.',
      hasScheduleBased,
      hasPrediction,
    };
  });

  return predictionsToReturn;
};

export const currentRouteColor = (state, getters) => {
  const currentColor = _.get(getters.currentRouteDetails, 'color');
  if (typeof currentColor !== 'string') {
    return null;
  }
  // Valid hex colors must be 6 or 3 (for shorthand) characters
  if (currentColor.length !== 6 && currentColor.length !== 3) {
    return null;
  }
  const hexColor = `#${currentColor}`;
  // Light colors are extremely difficult to see on the map
  // Therefore, only use them if they are somewhat dark
  const brightnessValue = _getBrightnessByColor(hexColor);
  const maximumBrightness = 215;
  if (brightnessValue > maximumBrightness) {
    return null;
  }
  return hexColor;
};

export const currentVehicle = (state, getters) => {
  const DEFAULT_RETURN_VALUE = null;
  const vehicles = getters.vehicles;
  const vehicleId = getters.currentVehicleId;
  const directionId = getters.currentDirectionId;
  if (!vehicleId) {
    return DEFAULT_RETURN_VALUE;
  }
  const vehicle = _.find(vehicles, { id: vehicleId });
  if (!_.isObjectLike(vehicle)) {
    return DEFAULT_RETURN_VALUE;
  }
  if (vehicle.directionId !== directionId) {
    return DEFAULT_RETURN_VALUE;
  }
  return vehicle;
};

// <private helpers>
const _getBrightnessByColor = (color) => {
  const DEFAULT_RETURN_VALUE = 0;
  if (typeof color !== 'string') {
    return DEFAULT_RETURN_VALUE;
  }
  const isHEX = color.indexOf('#') === 0;
  const isRGB = color.indexOf('rgb') === 0;
  let r = undefined;
  let g = undefined;
  let b = undefined;
  if (isHEX) {
    let hexWithoutHash = color.substr(1);
    if (hexWithoutHash.length === 3) {
      hexWithoutHash =
        hexWithoutHash.charAt(0) +
        hexWithoutHash.charAt(0) +
        hexWithoutHash.charAt(1) +
        hexWithoutHash.charAt(1) +
        hexWithoutHash.charAt(2) +
        hexWithoutHash.charAt(2);
    }
    const m = hexWithoutHash.match(/(\S{2})/g);
    if (m) {
      r = parseInt(m[0], 16);
      g = parseInt(m[1], 16);
      b = parseInt(m[2], 16);
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g);
    if (m) {
      r = m[0];
      g = m[1];
      b = m[2];
    }
  }
  if (typeof r === 'undefined') {
    return DEFAULT_RETURN_VALUE;
  }
  return (r * 299 + g * 587 + b * 114) / 1000;
};

const _getTimeDiffInMinutes = (predictionTime) => {
  if (!_.isInteger(predictionTime)) {
    return '';
  }
  const secondsInFuture = Math.round(predictionTime - Date.now() / 1000);
  return Math.floor(secondsInFuture / 60);
};
// </private helpers>
